import React from "react";
import SEOComponent from "../components/global/seo";
import LayoutComponent from "../components/global/layout";
import batphoneGif from '../resources/images/site/Batphone.gif'

const ContactPage = () => {
  return (
      <LayoutComponent>
        <SEOComponent title="Contact" 
            metaTitle="Contact | Surinder Bhomra"
            ogType={`article`} 
            shareImageIsLarge={true}
            metaDescription="Feel free to give me a shout!"
        />    
        <div className="divide-y">
          <header className="pt-6 pb-8 space-y-2 md:space-y-5">
            <h1 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl sm:leading-10 md:text-6xl md:leading-14"> 
              Contact
            </h1>
            <p>Feel free to give me a shout!</p>
          </header>
          <div className="space-y-2 xl:grid xl:grid-cols-3 xl:gap-x-8 xl:space-y-0">
            <div className="pt-8 sm:pt-6 prose dark:prose-dark max-w-none xl:col-span-2">
              <p>Feel free to grab my attention and contact me through most mainstream social platforms, such as:</p>
              <ul>
                <li><a href="https://twitter.com/SurinderBhomra" target="_blank" rel="noreferrer">Twitter</a></li>
                <li><a href="https://www.linkedin.com/in/surinder-bhomra-95643130" target="_blank" rel="noreferrer">LinkedIn</a></li>
              </ul>
              <p>If you have any questions or enquiries regarding my posts, it's preferable to leave a comment on the respective post as your feedback can help others.</p>
              <p>I like to think I am quite proactive in responding to messages, but if you don't get a response immediately it's probably because I am either neck deep on a project that requires my utmost attention, or driving my pride and joy to some random place. I'll get back to you as soon as I can.</p>
            </div>
            <div className="flex flex-col items-center pt-8 space-x-2">
                <div className="block items-center justify-between">
                  <div className="relative items-center mb-3 overflow-hidden rounded-full h-80 w-80">
                    <img src={batphoneGif} alt="Bat Phone" className="inline" />
                  </div>
                </div>
            </div>
          </div>
        </div>
      </LayoutComponent>
  )
}

export default ContactPage
